<div class="layout-min-height">

  <h1 class="text-center mt-5">Azure Service Portal</h1>
  <div class="px-4 py-5 md:px-6 lg:px-8">
    <div class="grid justify-content-end">

      <div class="col-12 md:col-6 lg:col-4">
      </div>
      <!--<a class="col-12 md:col-6 lg:col-4" routerLink="./ams">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">AMS</span>
              <div class="text-900 font-medium text-xl">Account Management</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-user-edit text-red-500 text-xl"></i>
            </div>
          </div>
        </div>
      </a>-->

      <a class="col-12 md:col-6 lg:col-4" routerLink="./nps">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">NPS</span>
              <div class="text-900 font-medium text-xl">Network Management</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-sitemap text-blue-500 text-xl"></i>
            </div>
          </div>
          <!--<span class="text-green-500 font-medium">24 new </span>-->
          <!--<span class="text-500">since last visit</span>-->
        </div>
      </a>

      <a class="col-12 md:col-6 lg:col-4" routerLink="./iam">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">IAM</span>
              <div class="text-900 font-medium text-xl">Identity &amp; Access Management</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-id-card text-green-500 text-xl"></i>
            </div>
          </div>
        </div>
      </a>

      <!--<a class="col-12 md:col-6 lg:col-4" routerLink="./tms">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">TMS</span>
              <div class="text-900 font-medium text-xl">Tenant Management</div>
            </div>
            <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
              <i class="pi pi-home text-gray-500 text-xl"></i>
            </div>
          </div>
        </div>
      </a>-->

    </div>
  </div>
</div>
