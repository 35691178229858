import { Component, OnInit } from '@angular/core';
import { EventError } from '@azure/msal-browser';
import { PortalCommunicationService } from '../../../services/portal-communication.service';
import { NpsCommunicationService } from '../../../../nps/services/nps-communication.service';

@Component({
    selector: 'login-failed',
    templateUrl: './login-failed.component.html',
})
export class LoginFailedComponent implements OnInit {
  message?: string;
  constructor(
    private portalCommunication: PortalCommunicationService,
    private npsCommunication: NpsCommunicationService
  ) { }

  ngOnInit() {
    this.message = (this.portalCommunication.userLoginCompleted$.value as EventError)?.message
    if (this.message) {
      this.portalCommunication.userLoginCompleted$.next(null);
    }

    if (!this.message) {
      this.message = (this.npsCommunication.userLoginCompleted$.value as EventError)?.message
      if (this.message) {
        this.npsCommunication.userLoginCompleted$.next(null);
      }
    }

    if (!this.message) {
      this.message = 'Error during login';
    }
  }
}
