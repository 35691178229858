import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';

import { HomeRoutingModule } from './home-routing.module';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home.component';
import { NotfoundComponent } from './components/notfound.component';
import { LoginFailedComponent } from './components/login-failed.component';
import { TenantNotfoundComponent } from './components/tenant-notfound.component';

@NgModule({
  declarations: [
    HomeComponent,
    NotfoundComponent,
    LoginFailedComponent,
    TenantNotfoundComponent
  ],
  imports: [
    RouterModule,

    CommonModule,
    HomeRoutingModule,

    ButtonModule,
    RouterModule
  ]
})
export class HomeModule {}

